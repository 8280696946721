@use "shared/dist/styles/variables.scss" as v;

$card-sides-gap-sm: v.$spacing-sm;
$card-sides-gap-md: v.$spacing-md;
.managed-funds-withdraw-modal {
  .balance-after-withdrawal {
    opacity: 0.75;
  }

  .card {
    padding: v.$spacing-md $card-sides-gap-sm;

    @include v.breakpoint(v.$screen-md) {
      padding: $card-sides-gap-md;
    }
  }

  .form-radio--checked + label {
    color: v.$color-dusk;
  }

  .next-step-arrow {
    padding-bottom: v.$spacing-xl;
    padding-top: v.$spacing-lg;

    @include v.breakpoint(v.$screen-md) {
      padding-top: v.$spacing-xl;
      padding-bottom: v.$spacing-xl;
    }
  }

  .funds-list {
    margin: 0;
    padding-left: v.$spacing-sm;

    @include v.breakpoint(v.$screen-md) {
      padding-left: v.$spacing-2xl;
    }

    li {
      &:not(:last-of-type) {
        border-bottom: 1px solid v.$color-border;
      }

      @include v.breakpoint(v.$screen-md) {
        padding-left: v.$spacing-xs;
        padding-right: v.$spacing-xs;
      }
    }
  }
}