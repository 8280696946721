.loading-spinner {
  color: #00447C; // MUI CircularProgress primary color
  width: 100%;

  &.center {
    text-align: center;
  }

  .MuiCircularProgress-root,
  .MuiCircularProgress-svg {
    color: inherit;
  }
}
