@use "/src/styles/dashboard-theme.scss" as dashboard;
@use "shared/dist/styles/variables" as *;

$padding-phone: $spacing-md;
$padding-laptop: $spacing-lg;
.material-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiDialog-paperFullScreen {
    max-height: 100%;
  }

  a {
    color: $color-dusk;
  }

  &.third-party-modal {
    .MuiDialog-container .MuiPaper-root {
      height: 100%;

      > .modal-body {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    @include breakpoint($screen-md) {
      .MuiDialog-container {
        height: 90%;
        max-height: 1024px;
        max-width: 1024px;
      }
    }
  }

  &.auto-height {
    .MuiDialog-paperFullScreen {
      @media (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1),
        (min-device-width: 768px) and (min-resolution: 192dpi),
        (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
        (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
        height: auto;
      }
    }
  }

  .MuiPaper-root {
    padding: $padding-phone 0;

    @include breakpoint($screen-lg) {
      padding: $padding-laptop 0;
    }
  }

  .header,
  .content {
    padding: 0 $spacing-md;

    @include breakpoint($screen-lg) {
      padding: 0 $spacing-lg;
    }

    h4 {
      color: $color-midnight;
    }
  }

  .MuiDialogContent-root:not(.content) {
    padding: 0;
  }

  .content {
    @extend .text-regular;
    color: $c-text-dark;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
  }

  .close-button .MuiSvgIcon-root {
    transform: scale(0.8);
    fill: $color-midnight;
  }

  .iframe {
    @media (max-width: 600px) {
      margin: 0 -1rem;
    }

    iframe {
      border: 0;
      height: 100%;
      width: 100%;
    }
  }

  .MuiDialog-container {
    height: 100%;
    width: 100%;
    max-width: 800px;
    position: relative;

    @include breakpoint($screen-md) {
      max-height: calc(100% - ($spacing-xl * 2));
    }

    > .MuiDialog-paper {
      position: static;
    }

    .MuiPaper-root {
      border-radius: 0;
      height: auto;

      @include breakpoint($screen-md) {
        border-radius: 0.5rem;
      }
    }
  }

  .header {
    align-items: flex-start;
    margin-bottom: $spacing-md;
    position: relative;

    .close-button {
      flex: 0 0 auto;
      padding: $spacing-xs;
      position: relative;
      top: -$padding-phone;
      right: -$padding-phone;

      @include breakpoint($screen-lg) {
        top: -$padding-laptop;
        right: -$padding-laptop;
      }

      &:hover {
        border: 0;
      }

      &:active,
      &:focus,
      &:focus-visible {
        outline: 1px solid $c-button-primary-light;
      }
    }
  }

  &.auth-modal,
  &.rename-account-modal {
    .MuiDialog-container {
      max-width: 470px;

      .MuiPaper-root {
        border-radius: 0.5rem;
      }
    }
  }

  // Modal doesn't have background by default but we provide the element to allow customisation
  // currently being used for Premium Service switch modal
  .modal-background {
    content: "";
    display: none;
    inset: 0;
    position: absolute;
    z-index: 0;
  }

  .modal-body {
    z-index: 1;
  }

  .modal-subtitle {
    color: $color-dusk;
  }
}
