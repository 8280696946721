@use 'shared/dist/styles/variables' as v;

.mf-withdraw-completed {
  .label-value {
    padding-top: v.$spacing-sm;

    &:not(:last-of-type) {
      border-bottom: 1px solid v.$color-border;
      padding-bottom: v.$spacing-sm;
    }
  }

  .funds-list {
    border-top: 1px solid v.$color-border;
    margin-top: v.$spacing-sm;

    @include v.breakpoint(v.$screen-md) {
      padding-left: v.$spacing-xl;
    }
  }
}