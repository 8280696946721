@use '/src/styles/pretty-card.scss';

.account-transactions-history-component {
  @extend .pretty-card;

  background: none;
  border-radius: 0;
  padding: 0;
  position: relative;

  .table-content {
    overflow-x: auto;
    padding-bottom: 0.35rem;
    padding-top: 1rem;

    &-wrapper {
      overflow: hidden;
    }
  }
  .description-content {
    text-wrap: wrap;
  }
}
